<template>
  <div>
    <Toaster />
    <LoadersFullScreenLoader2 v-if="showFullScreenLoader" />
    <NuxtLayout>
      <NuxtPage />
      <!-- <NuxtPwaManifest /> -->
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { watchImmediate } from '@vueuse/core'
import { useAuthStore } from './stores/auth'
import { useProfileStore } from './stores/profile'
import { LeadBoosterController, isElementLoaded } from './lib/utils'
import {
  initPendo,
  setIdentityOnClarity,
  setTagsOnClarity,
} from './lib/externalIntegrations'
import Toaster from '@/components/ui/toast/the-toaster.vue'
const authStore = useAuthStore()
const profileStore = useProfileStore()
const { isLoggedIn } = storeToRefs(authStore)
const { profile, currentlySelectedOrganisation, organisationId } =
  storeToRefs(profileStore)
const { $listen, $bugsnag } = useNuxtApp()
const { initProfileAndOrganisation } = useUserFlow()
const miscellaneousStore = useMiscellaneousStore()
const { showFullScreenLoader } = storeToRefs(miscellaneousStore)
const featureFlagStore = useFeatureFlagStore()
const { initializeFeatureFlags } = featureFlagStore

const mixpanelInstance = useMixpanel()
const router = useRouter()
const currentRoute = computed(() => router.currentRoute.value)
watchImmediate(isLoggedIn, (newVal) => {
  if (newVal) {
    ;(async () => {
      miscellaneousStore.$patch({ showFullScreenLoader: true })
      await initProfileAndOrganisation()
      miscellaneousStore.$patch({ showFullScreenLoader: false })
    })()
  }
})

watchImmediate(organisationId, (newVal) => {
  if (newVal) {
    initializeFeatureFlags()
  }
})

$listen('error:report', (error) => {
  $bugsnag.notify(error)
})
$listen('track:mixpanel', ({ event, data = {} }) => {
  if (mixpanelInstance.value) {
    mixpanelInstance.value.track(event, data)
  } else {
    // eslint-disable-next-line no-console
    console.log('local event:', { event, data })
  }
})

watch(
  [isLoggedIn, profile, currentlySelectedOrganisation],
  (newVal) => {
    const [loggedIn, userProfile, currentOrg] = newVal
    if (!loggedIn || !userProfile || !currentOrg) return

    initPendo(currentOrg.id, userProfile)
    setIdentityOnClarity(userProfile)
    setTagsOnClarity(userProfile, currentOrg)
  },
  { immediate: true, deep: true },
)

watch(
  currentRoute,
  (newVal) => {
    if (newVal.name === 'dashboard') {
      LeadBoosterController.show()
    } else {
      LeadBoosterController.hide()
    }
  },
  { deep: true },
)

onMounted(() => {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister()
      })
    })
  }
})

onMounted(async () => {
  await isElementLoaded('#LeadboosterContainer')

  currentRoute.value.name === 'dashboard'
    ? LeadBoosterController.show()
    : LeadBoosterController.hide()
})
</script>
