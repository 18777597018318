import { default as _91_46_46_46404_93aYSM6Wm4UlMeta } from "/opt/build/repo/pages/[...404].vue?macro=true";
import { default as indexWXTYeJThB4Meta } from "/opt/build/repo/pages/analytics/index.vue?macro=true";
import { default as indexLi8m1MnRtfMeta } from "/opt/build/repo/pages/bills/index.vue?macro=true";
import { default as newe1qbJ5cdBJMeta } from "/opt/build/repo/pages/bills/new.vue?macro=true";
import { default as go_45cardless_45mandateZo8G2cTu3jMeta } from "/opt/build/repo/pages/callback/go-cardless-mandate.vue?macro=true";
import { default as indexP4kNxWOzXXMeta } from "/opt/build/repo/pages/callback/index.vue?macro=true";
import { default as indexpn5Vg3axUtMeta } from "/opt/build/repo/pages/callback/open-banking-payment/index.vue?macro=true";
import { default as payrollqdwk6hoeQFMeta } from "/opt/build/repo/pages/callback/open-banking-payment/payroll.vue?macro=true";
import { default as open_45bankingU1w6ggKZiRMeta } from "/opt/build/repo/pages/callback/open-banking.vue?macro=true";
import { default as quickbooks_45disconnected5HPduk3YUlMeta } from "/opt/build/repo/pages/callback/quickbooks-disconnected.vue?macro=true";
import { default as stripe_45message3jgNopAxujMeta } from "/opt/build/repo/pages/callback/stripe-message.vue?macro=true";
import { default as stripeiYonQhXumjMeta } from "/opt/build/repo/pages/callback/stripe.vue?macro=true";
import { default as contract8WGFwyCggbMeta } from "/opt/build/repo/pages/contract.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexYw9kJj45fLMeta } from "/opt/build/repo/pages/onboarding/index.vue?macro=true";
import { default as select_45productsWZVTrOqyTzMeta } from "/opt/build/repo/pages/onboarding/select-products.vue?macro=true";
import { default as onboardingRPxvyUqUaXMeta } from "/opt/build/repo/pages/onboarding.vue?macro=true";
import { default as createvTxFjqxQ6FMeta } from "/opt/build/repo/pages/organisation/create.vue?macro=true";
import { default as selectuVITCUdrjVMeta } from "/opt/build/repo/pages/organisation/select.vue?macro=true";
import { default as payrolldumjdu4GjCMeta } from "/opt/build/repo/pages/payroll.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as indextNDTyjQH0SMeta } from "/opt/build/repo/pages/repayments/index.vue?macro=true";
import { default as businessHLmugy252tMeta } from "/opt/build/repo/pages/settings/business.vue?macro=true";
import { default as connections3vCkxWgqAgMeta } from "/opt/build/repo/pages/settings/connections.vue?macro=true";
import { default as profileNcyTuBLl16Meta } from "/opt/build/repo/pages/settings/profile.vue?macro=true";
import { default as TeamxTgCn3HjeZMeta } from "/opt/build/repo/pages/settings/Team.vue?macro=true";
import { default as silent_45refreshPhwFclukqRMeta } from "/opt/build/repo/pages/silent-refresh.vue?macro=true";
import { default as invitationsnsDZZ16ESiMeta } from "/opt/build/repo/pages/user/invitations.vue?macro=true";
import { default as indexv6Re68MFFvMeta } from "/opt/build/repo/pages/vendors/index.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    meta: _91_46_46_46404_93aYSM6Wm4UlMeta || {},
    component: () => import("/opt/build/repo/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/opt/build/repo/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "bills",
    path: "/bills",
    component: () => import("/opt/build/repo/pages/bills/index.vue").then(m => m.default || m)
  },
  {
    name: "bills-new",
    path: "/bills/new",
    component: () => import("/opt/build/repo/pages/bills/new.vue").then(m => m.default || m)
  },
  {
    name: "callback-go-cardless-mandate",
    path: "/callback/go-cardless-mandate",
    component: () => import("/opt/build/repo/pages/callback/go-cardless-mandate.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/opt/build/repo/pages/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "callback-open-banking-payment",
    path: "/callback/open-banking-payment",
    component: () => import("/opt/build/repo/pages/callback/open-banking-payment/index.vue").then(m => m.default || m)
  },
  {
    name: "callback-open-banking-payment-payroll",
    path: "/callback/open-banking-payment/payroll",
    component: () => import("/opt/build/repo/pages/callback/open-banking-payment/payroll.vue").then(m => m.default || m)
  },
  {
    name: "callback-open-banking",
    path: "/callback/open-banking",
    component: () => import("/opt/build/repo/pages/callback/open-banking.vue").then(m => m.default || m)
  },
  {
    name: "callback-quickbooks-disconnected",
    path: "/callback/quickbooks-disconnected",
    meta: quickbooks_45disconnected5HPduk3YUlMeta || {},
    component: () => import("/opt/build/repo/pages/callback/quickbooks-disconnected.vue").then(m => m.default || m)
  },
  {
    name: "callback-stripe-message",
    path: "/callback/stripe-message",
    component: () => import("/opt/build/repo/pages/callback/stripe-message.vue").then(m => m.default || m)
  },
  {
    name: "callback-stripe",
    path: "/callback/stripe",
    component: () => import("/opt/build/repo/pages/callback/stripe.vue").then(m => m.default || m)
  },
  {
    name: "contract",
    path: "/contract",
    component: () => import("/opt/build/repo/pages/contract.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingRPxvyUqUaXMeta?.name,
    path: "/onboarding",
    meta: onboardingRPxvyUqUaXMeta || {},
    component: () => import("/opt/build/repo/pages/onboarding.vue").then(m => m.default || m),
    children: [
  {
    name: "onboarding",
    path: "",
    component: () => import("/opt/build/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-select-products",
    path: "select-products",
    component: () => import("/opt/build/repo/pages/onboarding/select-products.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisation-create",
    path: "/organisation/create",
    meta: createvTxFjqxQ6FMeta || {},
    component: () => import("/opt/build/repo/pages/organisation/create.vue").then(m => m.default || m)
  },
  {
    name: "organisation-select",
    path: "/organisation/select",
    meta: selectuVITCUdrjVMeta || {},
    component: () => import("/opt/build/repo/pages/organisation/select.vue").then(m => m.default || m)
  },
  {
    name: "payroll",
    path: "/payroll",
    component: () => import("/opt/build/repo/pages/payroll.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "repayments",
    path: "/repayments",
    component: () => import("/opt/build/repo/pages/repayments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-business",
    path: "/settings/business",
    component: () => import("/opt/build/repo/pages/settings/business.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "/settings/connections",
    component: () => import("/opt/build/repo/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    component: () => import("/opt/build/repo/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-Team",
    path: "/settings/Team",
    component: () => import("/opt/build/repo/pages/settings/Team.vue").then(m => m.default || m)
  },
  {
    name: "silent-refresh",
    path: "/silent-refresh",
    component: () => import("/opt/build/repo/pages/silent-refresh.vue").then(m => m.default || m)
  },
  {
    name: "user-invitations",
    path: "/user/invitations",
    meta: invitationsnsDZZ16ESiMeta || {},
    component: () => import("/opt/build/repo/pages/user/invitations.vue").then(m => m.default || m)
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/opt/build/repo/pages/vendors/index.vue").then(m => m.default || m)
  }
]